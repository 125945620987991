.container.experience-container {
  width: 40%;
  padding-bottom: 3rem;
}

.experience-skills {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 1rem;
  font-size: 4rem;
}

.experience-item {
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.job-desc {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

.job-desc li {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
}

.job-desc p {
  font-size: 0.9rem;
  text-align: left;
}

.job-desc-icon {
  color: var(--color-primary);
  margin-top: 2px;
  flex-shrink: 0;
  font-size: 1.2rem;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
  width: 1rem;
  height: 1rem;
}

/* MEDIA QUERIES : MEDIUM DEVICES */
@media screen and (max-width: 1024px) {
  .container.experience-container {
    width: 60%;
  }
}

/* MEDIA QUERIES : SMALL DEVICES */
@media screen and (max-width: 600px) {
  .container.experience-container {
    width: var(--container-width-sm);
  }

  .job-desc {
    width: var(--container-width-sm);
  }
}