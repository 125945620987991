header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header-container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* CALL TO ACTION STYLES */
.cta {
  margin-top: 1.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* SOCIALS STYLES */
.header-socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left:0;
  bottom: 0;
  font-size: 2rem;
}

.header-socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* PROFILE IMAGE STYLES */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 3rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  /* for different size images */
  /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

/* SCROLL DOWN LINK */
.scroll-down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1rem;
}

/* MEDIA QUERIES : MEDIUM DEVICES */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* MEDIA QUERIES : SMALL DEVICES */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header-socials, .scroll-down {
    display: none;
  }
}