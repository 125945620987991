.portfolio-container {
  display: grid;
  /* https://css-tricks.com/introduction-fr-css-unit/ */
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio-item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent; 
  transition: var(--transition);
}

.portfolio-item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio-item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio-item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio-item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.portfolio-image {
  /* width:  100px; */
  height: 12rem;
  object-fit: cover;
}

/* MEDIA QUERIES : MEDIUM DEVICES */
@media screen and (max-width: 1024px) {
  .portfolio-container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* MEDIA QUERIES : SMALL DEVICES */
@media screen and (max-width: 600px) {
  .portfolio-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}